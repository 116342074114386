import React from "react";

const Heading = () => {
  return (
    <div className="flex justify-center items-center md:p-20 py-10 relative z-10">
      <div>
        <h1 className="font-black md:text-7xl text-3xl text-center">Roadmap</h1>
        <br />
        <div className="flex justify-center items-center">
          <div className="md:w-2/3 text-center text-opacity-5">
            Follow our progress as we build capital efficiency products for the
            NFT space and the metaverse!
          </div>
        </div>
      </div>
    </div>
  );
};

export default Heading;
