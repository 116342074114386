import React from "react";
import { cardStyle } from "../../styles";

const Element = ({ title, subtitle }: { title: string; subtitle: string }) => {
  return (
    <div className="mb-8">
      <div className="font-light flex">
        <div className="w-2 h-2 bg-green-400 rounded-sm mr-5 mt-2" />
        <div>
          <p className="font-semibold mb-2 text-white">{title}</p>
          <p className="text-gray-300">{subtitle}</p>
        </div>
      </div>
    </div>
  );
};

const DetailDesktop = () => {
  return (
    <div className="block">
      <div
        className="bg-blend-multiply rounded-lg w-full grid md:grid-cols-1 gap-4 px-10 py-12"
        style={cardStyle}
      >
        <div className="flex mb-6">
          <div className="w-8 mt-1 mr-2">
            <div className="border-gray-600 border-4 w-5 h-5 -ml-2" />
            <div className="bg-gray-600 h-full w-1 mt-2" />
          </div>
          <div>
            <h4 className="text-lg font-bold mb-6">Q4 2022</h4>
            <Element
              title="Loan History"
              subtitle="History of previous loans of asset on the platform."
            />
            <Element
              title="Calendar Reminder"
              subtitle=" Never miss an instalment now. Users can add the instalment date
              to Google Calendar/iCal."
            />
            <Element
              title="Integrations"
              subtitle="Price assessment tool integration and partnership with real
              world NFT asset issuers"
            />
            <Element
              title="Yield Opportunities"
              subtitle="Integration of liquid staking protocol on the platform for
              direct yield farming opportunities"
            />

            <Element
              title="New Data Product Line"
              subtitle="Data aggregation of NFTs multichain with multiple data points of
              NFTs from marketplaces."
            />
          </div>
        </div>
        <div className="flex">
          <div className="w-8 mr-2">
            <div className="border-gray-600 border-4 w-5 h-5 -ml-2" />
          </div>
          <div>
            <h4 className="text-lg font-bold mb-6">Q1 2023</h4>
            <Element
              title="Unlimited Duration Loans"
              subtitle="Borrowers decide custom tenure for which they want to
              collateralise their NFTs"
            />
            <Element
              title="Crunchbase for NFTs"
              subtitle="NFT Analytics and insights platform with custom filters"
            />
            <Element
              title="Enabling Discovery"
              subtitle="Alert system to receive notifications for discovery of assets"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailDesktop;
