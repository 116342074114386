import * as React from "react";

import Layout from "../components/layouts";
import Pager from "../components/layouts/Pager";
import DetailMobile from "../components/roadmap/DetailMobile";
import Heading from "../components/roadmap/Heading";
import backGround from "../images/crystal_roadmap.png";

// markup
const IndexPage = () => {
  return (
    <Layout hideTeam>
      <div
        className="pb-60"
        style={{
          background: `url(${backGround})`,
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Pager>
          <Heading />
          <DetailMobile />
        </Pager>
      </div>
    </Layout>
  );
};

export default IndexPage;
